import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function formatCurrency(input?: number | null) {
	if (Number.isNaN(input) || input === null || input === undefined) {
		return "–";
	}
	return new Intl.NumberFormat("en-GB", {
		style: "currency",
		currency: "EUR",
		minimumFractionDigits: 0,
	}).format(input);
}

export function getInitials(name?: string) {
	const DEFAULT_INITIALS = "VV";
	try {
		if (!name) return DEFAULT_INITIALS;

		const [first, last] = name.split(" ");
		return `${first[0]}${last[0]}`;
	} catch (error) {
		return DEFAULT_INITIALS;
	}
}
